import React from 'react';
import Layout from 'templates/Layout/Layout';
import Container from 'components/atoms/Container/Container';
import Footer from 'components/organisms/Footer/Footer';
import CustomHeader from 'components/organisms/CustomHeader/CustomHeader';
import { colors } from 'utils/variables';
import styled from 'styled-components';

const Styled404 = styled.p`
  font-size: 10rem;
  font-weight: 600;
  color: ${colors.color3};
  text-align: center;
`;

const Page = () => (
  <Layout metaTitle="404 - Strona nie istnieje" metaDescription="Strona nie istnieje">
    <CustomHeader title="Strona <b>nie istnieje</b>" />
    <Container>
      <Styled404>404</Styled404>
    </Container>
    <Footer />
  </Layout>
);

export default Page;
